import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  constructor(private http: HttpClient) { }

  getData() {
    return this.http.get("https://ixpmanager.jix.net.au/api/v4/member-export/ixf/0.6");
  }
}
