import { Component, OnInit } from '@angular/core';
import { ParticipantService } from '../participant.service'

@Component({
  selector: 'jix-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.css'],
})
export class DirectoryComponent implements OnInit {
  loading = false;
  participants = null;

  constructor(participantService: ParticipantService) {
    this.loading = true;
    participantService.getData()
      .subscribe(participants => {
        this.participants = participants;
        this.loading = false;
      });
  }

  ngOnInit() {
  }

}
