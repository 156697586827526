import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import find from 'lodash/find';
import filter from 'lodash/filter';
import some from 'lodash/some';

const facilityHacks = {
  "JIX Darwin": [
    { name: "Secure Data Center", peeringDb: 1831 }
  ],
  "JIX Hobart": [
  ],
  "JIX Adelaide": [
    { name: "YourDC - Edinburgh Parks", peeringDb: 4044 },
    { name: "YourDC - Hawthorn", peeringDb: 4045 }
  ]
};

@Component({
  selector: 'jix-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.css']
})
export class ExchangeComponent {
  @Input() participants;
  @Input() ixpId;

  exchange = null;
  peers = null;
  pops = [];

  columns = [
    { prop: 'name', name: 'Peer Name' },
    { prop: 'asnum', name: 'ASN' }
  ];



  ngOnChanges() {
    if( this.participants !== null && this.ixpId !== null ) {
      this.exchange = find(this.participants.ixp_list, ixp => ixp.ixp_id == this.ixpId);
      this.peers = filter(this.participants.member_list, member => some(member.connection_list, conn => conn.ixp_id == this.ixpId));

      this.pops = this.exchange.shortname in facilityHacks ? facilityHacks[this.exchange.shortname] : [];
    }
  }
}
